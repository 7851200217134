import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import groupImg from "../assets/images/Group 2247.png";
import arrowIcon from "../assets/images/Vector 201.png";
import signupBg from "../assets/images/Logo Transparent 2.png";
import googleImage from "../assets/images/google 1.png";
import grayLineVector from "../assets/images/Vector 204.png";
import lockVector from "../assets/images/Vector (1).png";
import checkIcon from "../assets/images/check-icon.png";
import crossIcon from "../assets/images/cross-icon.png";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const Signup = () => {
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  const [fileData, setFileData] = useState(null);
  const [cvErr, setCvErr] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const formTop = useRef(null);
  const uploadProgress = useRef(null);
  const progressText = useRef(null);
  const uploadDiv = useRef(null);
  const progressMainDiv = useRef(null);
  const waitPara = useRef(null);
  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) validateAndUploadFile(file);
  };

  const validateAndUploadFile = (file) => {
    const maxFileSize = 10 * 1024 * 1024; // 10 MB
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!allowedTypes.includes(file.type)) {
      updateProgressBar(100);
      waitPara.current.style.display = "none";
      progressText.current.innerHTML = `<img src=${crossIcon} alt="cross-icon" style="width: 35px; height: 35px;" />`;
      setCvErr("Invalid file type. Only PDF, DOC, and DOCX files are allowed.");
      return;
    }

    if (file.size > maxFileSize) {
      updateProgressBar(100);
      waitPara.current.style.display = "none";
      progressText.current.innerHTML = `<img src=${crossIcon} alt="cross-icon" style="width: 35px; height: 35px;" />`;
      setCvErr("The file exceeds the maximum allowed size of 10MB.");
      return;
    }

    setCvErr(false);
    setFileData(file);
    uploadFile(file);
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("cv-file", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "", true);

    xhr.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / e.total) * 100;
        updateProgressBar(percentComplete);
      }
    });

    xhr.upload.addEventListener("load", () => {
      updateProgressBar(100);
      waitPara.current.style.display = "none";
      progressText.current.innerHTML = `<img src=${checkIcon} alt="check-icon" style="width: 50px; height: 50px;" />`;
    });

    xhr.send(formData);
  };

  const updateProgressBar = (progress) => {
    if (
      uploadProgress.current &&
      progressText.current &&
      progressMainDiv.current &&
      uploadDiv.current
    ) {
      progressMainDiv.current.style.display = "flex";
      uploadDiv.current.style.display = "none";
      progressText.current.innerHTML = Math.round(progress) + "%";
      if (progress < 100) {
        const rotation = (progress / 100) * 360;
        uploadProgress.current.style.background = `conic-gradient(
          #6293F4,
          #5B8EF0,
          #5489EC,
          #467FE5,
          #3F7AE2,
          #3875DE,
          #316FD9,
          #2A6AD6,
          #2354B3,
          #1C3F8F,
          #153A6C,
          #0E3548,
          #072D28,
          #02256E ${rotation}deg,
          transparent ${rotation + 15}deg
        )`;
      } else {
        const rotation = (progress / 100) * 360;
        uploadProgress.current.style.background = `conic-gradient(
          #6293F4 ${rotation}deg,
          transparent ${rotation + 15}deg
        )`;
      }
    } else {
      console.error("One or more elements are missing.");
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setShowErrorAlert(false);
    setShowSuccessAlert(false);
    const form = new FormData();
    form.append("firstName", values.firstName);
    form.append("lastName", values.lastName);
    form.append("email", values.email);
    form.append("password", values.password);
    form.append("confirmPassword", values.confirmPassword);
    if (fileData) form.append("cv-file", fileData);

    try {
      const response = await fetch(`${serverBaseUrl}/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: form,
      });
      const responseData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setShowErrorAlert(false);
        const user = responseData.response.data;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", responseData.response.token);
        setAlertMessage(responseData?.message);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate("/dashboard");
        }, 1000);
      } else if (response.status === 422) {
        setAlertMessage(responseData?.errors);
        setShowErrorAlert(true);
        setTimeout(() => setShowErrorAlert(false), 3000);
        return;
      } else {
        setAlertMessage(responseData?.message);
        setShowErrorAlert(true);
        setTimeout(() => setShowErrorAlert(false), 3000);
        return;
      }
    } catch (error) {
      console.error(error);
      setAlertMessage("An error occurred during signup");
      setShowErrorAlert(true);
      setTimeout(() => setShowErrorAlert(false), 3000);
    } finally {
      setSubmitting(false);
      if (formTop.current) {
        formTop.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      validateAndUploadFile(files[0], "dropFile");
    }
  };

  const cancelUpload = () => {
    setFileData(null);
    uploadDiv.current.style.display = "block";
    progressMainDiv.current.style.display = "none";
    waitPara.current.style.display = "block";
    document.getElementById("cv-file").value = "";
    setCvErr("");
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, "Too Short!")
      .max(70, "Too Long!")
      .trim()
      .matches(
        /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/,
        "Please enter a valid first name without numbers"
      )
      .required("First name is required"),
    lastName: Yup.string()
      .min(1, "Too Short!")
      .max(70, "Too Long!")
      .trim()
      .matches(
        /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/,
        "Please enter a valid last name without numbers"
      )
      .required("Last name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, and one number."
      )
      .required("Password is required."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    cv: Yup.mixed()
      .test(
        "fileType",
        "Image must be a valid file (doc, docx, pdf)",
        (value) =>
          !value ||
          [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(value.type)
      )
      .nullable()
      .test(
        "fileSize",
        "File size too large. Maximum 10MB allowed.",
        (value) => !value || value.size <= 10 * 1024 * 1024
      ),
  });
  // const handleFileChange = (event) => {
  //   const file = event.currentTarget.files[0];
  //   if (!file) return;

  //   const maxFileSize = 5 * 1024 * 1024; // 5 MB
  //   if (file.size > maxFileSize) {
  //     setCvErr("The file exceeds the maximum allowed size.");
  //     event.currentTarget.value = "";
  //     return;
  //   } else {
  //     setCvErr(false);
  //   }

  //   setFileData(file);
  //   uploadFile(file);
  // };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setProfile(res.data);
          handleGoogleSubmit(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const handleGoogleSubmit = async (profiles) => {
    setShowErrorAlert(false);
    setShowSuccessAlert(false);
    const form = new FormData();
    form.append("firstName", profiles.given_name);
    form.append("lastName", profiles.family_name);
    form.append("email", profiles.email);
    form.append("providerId", profiles.id);

    try {
      const response = await fetch(`${serverBaseUrl}/google-login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: form,
      });
      const responseData = await response.json();
      if (response.status === 200 || response.status === 201) {
        const user = responseData.response.data;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", responseData.response.token);
        setAlertMessage(responseData?.message);
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate("/dashboard");
        }, 1000);
      } else if (response.status === 422) {
        setAlertMessage(responseData?.errors);
        setShowErrorAlert(true);
        setTimeout(() => setShowErrorAlert(false), 3000);
        return;
      } else {
        setAlertMessage(responseData?.message);
        setShowErrorAlert(true);
        setTimeout(() => setShowErrorAlert(false), 3000);
        return;
      }
    } catch (error) {
      console.error(error);
      setAlertMessage("An error occurred during signup");
      setShowErrorAlert(true);
      setTimeout(() => setShowErrorAlert(false), 3000);
    } finally {
      if (formTop.current) {
        formTop.current.scrollIntoView({ behavior: "smooth" });
      }
      setProfile([]);
    }
  };

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  return (
    <div className="body">
      <div className="row g-0 h-100 w-100">
        <div className="col-lg-4 d-none d-lg-flex flex-column align-items-center justify-content-between">
          <p
            className="px-5 text-start fs-18 mt-5"
            style={{ color: "#d6d6d6" }}
          >
            Find your best place to learn and growing your skills.
          </p>
          <img src={groupImg} className="group-img" alt="login group" />
          <div className="w-100">
            <p className="ps-5 text-white fs-14">
              © 2023 G LNK. All rights reserved.
            </p>
          </div>
        </div>
        <div className="col-lg-4 d-flex border bg-white form-radius">
          <div className="card-body p-0 px-4">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
                cv: null,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, handleSubmit, errors, touched }) => (
                <Form
                  encType="multipart/form-data"
                  id="form"
                  ref={formTop}
                  onSubmit={handleSubmit}
                >
                  <img
                    src={arrowIcon}
                    alt="arrow-icon"
                    className="position-absolute top-0 end-0 mt-md-4 mt-3 me-md-5 me-3"
                  />
                  <div className="my-md-3 my-2 ms-sm-4 ms-1 pb-1">
                    <Link to="/">
                      <img src={signupBg} alt="signup-background" />
                    </Link>
                  </div>

                  <div className="form">
                    <h3 className="text-center fw-bold">Create Account</h3>
                    <div className="d-flex align-items-center justify-content-center mt-sm-2 mt-2 mb-3 google-button">
                      <button
                        type="button"
                        onClick={() => login()}
                        className="flex align-items-center justify-content-between btn bg-transparent border-1 p-0 py-sm-2 py-1 google"
                      >
                        <img
                          src={googleImage}
                          alt="google-icon"
                          className="px-2 "
                        />
                        <span className="fw-bold pe-2">
                          Sign up with Google
                        </span>
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mb-md-2 mb-1 signup-or-line">
                      <img src={grayLineVector} alt="line-vector" />
                      <span
                        className="mx-2 fw-bold"
                        style={{ color: "#d4d4d4" }}
                      >
                        OR
                      </span>
                      <img src={grayLineVector} alt="line-vector" />
                    </div>

                    {showSuccessAlert && (
                      <div
                        id="alertBox"
                        className="w-100 d-flex align-items-center justify-content-center "
                      >
                        <div
                          className="alert alert-success alert-dismissible fade show d-flex align-items-center justify-content-center "
                          role="alert"
                        >
                          {alertMessage}
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                    )}

                    {showErrorAlert && (
                      <div
                        id="alertBox"
                        className="w-100 d-flex align-items-center justify-content-center "
                      >
                        <div
                          className="alert alert-danger alert-dismissible fade show d-flex align-items-center justify-content-center "
                          role="alert"
                        >
                          {alertMessage}
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                    )}
                    <div className="px-xl-5 px-3">
                      <div className="gap-5 d-flex form-outline mb-2">
                        <div className="w-50 ">
                          <label
                            className="form-label label-text"
                            style={{ fontWeight: 600 }}
                            htmlFor="firstName"
                          >
                            First Name <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control form-control-input ${
                              errors.firstName && touched.firstName
                                ? "is-invalid"
                                : ""
                            }`}
                            id="firstName"
                            name="firstName"
                            placeholder="First name"
                          />
                          {errors.firstName && touched.firstName && (
                            <span className="error-message">
                              {errors.firstName}
                            </span>
                          )}
                          {/* {errors.firstName && (
                            <p
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {errors.firstName}
                            </p>
                          )} */}
                        </div>
                        <div className="w-50">
                          <label
                            className="form-label label-text"
                            style={{ fontWeight: 600 }}
                            htmlFor="lastName"
                          >
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control form-control-input ${
                              errors.lastName && touched.lastName
                                ? "is-invalid"
                                : ""
                            }`}
                            id="lastName"
                            name="lastName"
                            placeholder="Last name"
                          />
                          {errors.lastName && touched.lastName && (
                            <span className="error-message">
                              {errors.lastName}
                            </span>
                          )}
                          {/* {errors.lastName && (
                            <p
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {errors.lastName}
                            </p>
                          )} */}
                        </div>
                      </div>
                      <div className="form-outline mb-2">
                        <label
                          className="form-label label-text"
                          style={{ fontWeight: 600 }}
                          htmlFor="email"
                        >
                          Email <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="email"
                          className={`form-control form-control-input ${
                            errors.email && touched.email ? "is-invalid" : ""
                          }`}
                          id="email"
                          name="email"
                          placeholder="Enter your Email Address"
                        />
                        {errors.email && touched.email && (
                          <span className="error-message">{errors.email}</span>
                        )}
                        {/* {errors.email && (
                          <p
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {errors.email}
                          </p>
                        )} */}
                      </div>

                      <div className="form-outline mb-2">
                        <label
                          className="form-label label-text"
                          style={{ fontWeight: 600 }}
                          htmlFor="password"
                        >
                          Password <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="password"
                          className={`form-control form-control-input ${
                            errors.password && touched.password
                              ? "is-invalid"
                              : ""
                          }`}
                          id="password"
                          name="password"
                          placeholder="Create Password"
                        />

                        {!errors.password ? (
                          <p
                            className="hint-password"
                            style={{ color: "#00000080" }}
                          >
                            Minimum 8 characters, at least one uppercase letter,
                            one lowercase letter, and one number
                          </p>
                        ) : (
                          errors.password &&
                          touched.password && (
                            <span className="error-message">
                              {errors.password}
                            </span>
                          )
                        )}
                      </div>

                      <div className="form-outline mb-2">
                        <label
                          className="form-label label-text"
                          style={{ fontWeight: 600 }}
                          htmlFor="confirmPassword"
                        >
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="password"
                          className={`form-control form-control-input ${
                            errors.confirmPassword && touched.confirmPassword
                              ? "is-invalid"
                              : ""
                          }`}
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Re-enter Password"
                        />
                        {errors.confirmPassword && touched.confirmPassword && (
                          <span className="error-message">
                            {errors.confirmPassword}
                          </span>
                        )}
                        {/* {errors.confirmPassword && (
                          <p
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {errors.confirmPassword}
                          </p>
                        )} */}
                      </div>
                      <label
                        className="form-label label-text"
                        style={{ fontWeight: 600 }}
                        htmlFor="uploadCV"
                      >
                        Upload CV{" "}
                        <span style={{ fontWeight: "200" }}>(optional)</span>
                      </label>
                      <div
                        className="position-relative d-flex flex-column border align-items-center box-radius"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        id="upload-cv-div"
                      >
                        <div className="position-absolute top-0 end-0 me-3 mt-sm-2 mt-1">
                          <img
                            src={lockVector}
                            alt="lock-vector"
                            className="vector-lock-image"
                          />
                        </div>
                        <div ref={uploadDiv}>
                          <input
                            type="file"
                            name="cv"
                            id="cv-file"
                            className="d-none form-control-file"
                            onChange={(event) => {
                              handleFileChange(event);
                              setFieldValue("cv", event.currentTarget.files[0]);
                            }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            accept=".pdf,.doc,.docx"
                          />
                          <label
                            htmlFor="cv-file"
                            className="btn btn-primary px-4 upload-cv-button"
                          >
                            Upload CV
                          </label>
                          <p className="mt-1 fw-bold text-center drop-para">
                            Drag & Drop Your File Here.
                          </p>
                        </div>
                        <div
                          id="progress-div-main"
                          ref={progressMainDiv}
                          className=" flex-column align-items-center justify-content-center none"
                        >
                          <div
                            className="progress-circle d-flex"
                            id="upload-progress"
                            ref={uploadProgress}
                          >
                            <div className="progress-circle-inner">
                              <span ref={progressText}>0%</span>
                            </div>
                          </div>
                          <p ref={waitPara} className="mb-0 fw-bold">
                            Wait a little bit
                          </p>
                          <button
                            className="btn my-2 px-4 py-0"
                            type="button"
                            id="cancel-btn"
                            onClick={cancelUpload}
                          >
                            Cancel
                          </button>
                        </div>
                        {cvErr ? (
                          <div className="px-sm-0 px-5 text-center error-message file-upload-type-text">
                            {cvErr}
                          </div>
                        ) : (
                          <p
                            id="file-error"
                            className="px-sm-0 px-5 text-center file-upload-type-text"
                            style={{ color: "#00000080" }}
                          >
                            Allowed file types: PDF, DOC, DOCX | Max file size:
                            10MB
                          </p>
                        )}
                      </div>

                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <button
                          id="submit"
                          type="submit"
                          disabled={cvErr}
                          className="btn text-white px-xxl-5 px-sm-3 signup-signup-button"
                          style={{ background: "#00236c" }}
                        >
                          Create Account
                        </button>
                        <p className="mt-2 signup-login-button">
                          Already have an account?{" "}
                          <Link
                            to="/login"
                            className="fw-bold link-dark text-decoration-none"
                          >
                            Log in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="col-lg-4 d-none d-lg-flex flex-column align-items-center justify-content-between"></div>
      </div>
    </div>
  );
};

export default Signup;
