import React from "react";
import footerlogo from "../assets/images/logo-footer.png";
import linkedin from "../assets/images/linkedin-bg.png";
import facebook from "../assets/images/facebook-bg.png";
import twitter from "../assets/images/twitter-bg.png";
import "../assets/css/footer.css";
import { Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-3 footer d-flex align-items-center">
      <div className="footer-div">
        <Row className="footer-div-row">
          <Col xs={7} lg={4} className="d-flex flex-column align-items-start ">
            <img src={footerlogo} alt="logo" className="mb-3 footer-logo" />
            <p className="footer-left-section text-capitalize text-white">
            Copyright © 2024. All Rights Reserved By GLNK
            </p>
          </Col>

          <Col
            xs={5}
            lg={6}
            className="d-flex flex-column align-items-sm-center link-section"
          >
            <h2 className="link-heading">Quick Link</h2>
            <ul className="list-unstyled d-flex flex-sm-row flex-column">
              <li>
                <a
                  href="/"
                  className="text-white text-decoration-none link-text"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-white text-decoration-none link-text"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/investors"
                  className="text-white text-decoration-none link-text"
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-white text-decoration-none link-text"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </Col>

          <Col xs={5} lg={2} className="d-flex flex-column align-items-start social-media-section">
            <h2 className="link-heading">Social Media</h2>
            <div className="d-flex mt-1">
              <a href="https://www.facebook.com" className="icon-image-link">
                <img src={facebook} alt="Facebook" className="icon-image" />
              </a>
              <a
                href="https://www.linkedin.com"
                className="icon-image-link icon-image-style"
              >
                <img src={linkedin} alt="LinkedIn" className="icon-image" />
              </a>
              <a
                href="https://www.twitter.com"
                className="icon-image-link icon-image-style"
              >
                <img src={twitter} alt="Twitter" className="icon-image" />
              </a>
            </div>
          </Col>

          {/* <Col xs={7} sm={12} className="text-center mt-sm-4 mt-1">
            <p className="mb-0 copyright-footer">
              Copyright © 2024. All Rights Reserved By GLNK
            </p>
          </Col> */}
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
