import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import networking from "../assets/images/networking.png";
import integration from "../assets/images/integration.png";
import design from "../assets/images/design.png";
import scalibility from "../assets/images/scalibility.png";
import security from "../assets/images/security.png";
import support from "../assets/images/support.png";

import "../assets/css/edge.css";

const Edge = () => {
  const features = [
    {
      icon: networking,
      title: "Innovative Networking",
      description:
        "Leveraging AI, we provide healthcare professionals with smart networking solutions that make connecting with the right people easier and more impactful.",
    },
    {
      icon: integration,
      title: "Seamless Integration",
      description:
        "Our platform integrates essential tools for research management, networking, and career development into one cohesive experience.",
    },
    {
      icon: design,
      title: "User-Centric Design",
      description:
        "Built with healthcare professionals in mind, G LNK Connect+ is intuitive, easy to navigate, and designed to streamline your work processes.",
    },
    {
      icon: scalibility,
      title: "Scalability",
      description:
        "Whether you're an individual researcher or part of a large organization, our platform scales to meet your needs, growing with your career and projects.",
    },
    {
      icon: security,
      title: "Data Security",
      description:
        "We prioritize the security of your data, ensuring that all your research, connections, and communications are protected with the highest standards.",
    },
    {
      icon: support,
      title: "24/7 Support",
      description:
        "Our dedicated support team is always available to assist you, providing 24/7 service to help you overcome any challenges.",
    },
  ];

  return (
    <div className="my-2 edge-section" id="edge">
      <h2 className="text-center edge-header">Our Edge</h2>

      <Row sm={8} xs={12}>
        {features.map((feature, index) => (
          <Col
            lg={4}
            sm={6}
            xs={12}
            key={index}
            className="mb-sm-4 border-0 p-sm-0 card-section-responsive"
          >
            <Card className="h-100 text-left card-edge border-0">
              <Card.Body className="card-body-edge">
                <div className="icon-container">
                  <img
                    className={`${feature.icon} icon-section display-4 mb-3 mt-3 p-3`}
                    src={feature.icon}
                    alt={feature.title}
                  ></img>
                </div>
                <h5 className="card-title">{feature.title}</h5>
                <p className="card-text mb-4">{feature.description}</p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Edge;
