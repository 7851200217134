import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import "../assets/css/pricing.css";

function Pricing() {
  const [isYearly, setIsYearly] = useState(false);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [numUsers, setNumUsers] = useState(10);

  const pricingData = {
    monthly: {
      10: "$9",
      25: "$19",
      50: "$29",
      100: "$39",
    },
    quarterly: {
      10: "$35",
      25: "$70",
      50: "$110",
      100: "$145",
    },
    yearly: {
      10: "$99",
      25: "$199",
      50: "$299",
      100: "$399",
    },
  };

  const allowedValues = Object.keys(pricingData[billingCycle]).map(Number);

  const updateSliderBackground = (value) => {
    const slider = document.getElementById("userCount");
    const percentage = value;
    slider.style.background = `linear-gradient(to right, #87AEEE 0%, #01236B ${percentage}%, white ${percentage}%, white 100%)`;
  };

  const handleUserChange = (event) => {
    const rawValue = Number(event.target.value);
    const clampedValue = Math.max(rawValue, 10);
    const closestValue = allowedValues.reduce((prev, curr) =>
      Math.abs(curr - clampedValue) < Math.abs(prev - clampedValue)
        ? curr
        : prev
    );
    setNumUsers(closestValue);
    updateSliderBackground(closestValue);
  };

  useEffect(() => {
    updateSliderBackground(numUsers);
  }, [numUsers]);

  return (
    <div id="pricing" className="pricing">
      <div className="starter flex flex-column">
        <h1>Simple, easy pricing</h1>
        <p>See our pricing for our several packages.</p>
      </div>
      <div className="card-div flex">
        <div className="pricing-card d-flex flex-column justify-content-between">
          <div>
            <h2 className="pricing-title">Individual</h2>
            <p className="pricing-amount">{isYearly ? "$99" : "$9"}</p>
            <div className="billing-toggle">
              <span
                className={`billing-option monthly ${
                  !isYearly ? "active" : ""
                }`}
                onClick={() => setIsYearly(false)}
              >
                / Monthly
              </span>
              <span
                className={`billing-option yearly ${isYearly ? "active" : ""}`}
                onClick={() => setIsYearly(true)}
              >
                / Yearly
              </span>
            </div>
            <p className="description-title">Simple, Transparent Pricing</p>
            <p className="pricing-subtext">
              All the essential tools to boost your healthcare research and
              networking.
            </p>
            <hr className="divider" />
            <ul className="pricing-features">
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                Full Access to G LNK Connect+
              </li>
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                AI-Driven Networking Suggestions
              </li>
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                Integrated Research Management Tools
              </li>
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                Real-Time Messaging and Collaboration
              </li>
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                Opportunity Discovery Tool
              </li>
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                24/7 Support
              </li>
            </ul>
          </div>
          <Link to="/signup">
            <button className="get-started-button rounded-pill">
              Get Started with <br /> G LNK Connect+ at No Cost
            </button>
          </Link>
        </div>
        <div className="pricing-card pricing-second-card d-flex flex-column justify-content-between corporate-card">
          <div>
            <h2 className="pricing-title">Corporate/institutional</h2>
            <p className="pricing-amount">
              {pricingData[billingCycle][numUsers]}
            </p>
            <div className="billing-toggle">
              <span
                className={`billing-option ${
                  billingCycle === "monthly" ? "active" : ""
                }`}
                onClick={() => setBillingCycle("monthly")}
              >
                / Monthly
              </span>
              <span
                className={`billing-option ${
                  billingCycle === "quarterly" ? "active" : ""
                }`}
                onClick={() => setBillingCycle("quarterly")}
              >
                / Quarterly
              </span>
              <span
                className={`billing-option ${
                  billingCycle === "yearly" ? "active" : ""
                }`}
                onClick={() => setBillingCycle("yearly")}
              >
                / Yearly
              </span>
            </div>
            <p className="description-title">Corporate/Institutional Plan</p>
            <p className="pricing-subtext">
              Advanced features for institutional research teams and
              organizations.
            </p>
            <div className="user-selection">
              <div className="tick-container">
                {allowedValues.map((value) => (
                  <div
                    key={value}
                    className="tick-mark d-flex"
                    style={{
                      left: `${value}%`,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <span
                      className={`tick-circle ${
                        numUsers === value ? "active" : ""
                      }`}
                    ></span>
                    <span className="tick-label">{value} users</span>
                  </div>
                ))}
              </div>

              <input
                type="range"
                id="userCount"
                value={numUsers}
                onChange={handleUserChange}
                className="user-slider"
                list="user-ticks"
                step={allowedValues[1] - allowedValues[0]}
              />
            </div>

            <ul className="pricing-features">
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                Everything in the Individual Plan, plus
              </li>
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                Organization-wide Collaboration Tools
              </li>
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                Customizable Networking Web
              </li>
              <li>
                <span className="circle flex">
                  <FaCheck color="#ffffff" />
                </span>{" "}
                Dedicated Account Support
              </li>
            </ul>
          </div>
          <Link to="/signup">
            <button className="get-started-button rounded-pill">
              Get Started with <br /> G LNK Connect+ at No Cost
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
