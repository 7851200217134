import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LiaBarsSolid } from "react-icons/lia";
import signupTopbar from '../assets/images/signup-hint.svg'
import "../assets/css/topbar.css";

import logo from "../assets/images/topbar-logo.png";

const Topbar = ({ menuBoolean = true }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 600) {
        closeDropdown();
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="topbar">
      <div className="topbar-div flex justify-content-start">
        <div className={`first-div ${menuBoolean && "hidden"}`}>
          <Link to="/">
            <img src={logo} alt="topbar-logo" />
          </Link>
        </div>
        <div
          className={`${menuBoolean ? "hamburger" : "d-none"}`}
          onClick={toggleDropdown}
        >
          <LiaBarsSolid size={26} color="#fff" />
        </div>
        {menuBoolean && (
          <>
            <div className={`second-div flex ${isDropdownOpen ? "open nav-links-mobile" : "nav-links"}`}>
              <a href="#services" className="link" onClick={closeDropdown}>
                Services
              </a>
              <a href="#how-work" className="link" onClick={closeDropdown}>
                How it works
              </a>
              <a href="#pricing" className="link" onClick={closeDropdown}>
                Pricing
              </a>
              <a href="#edge" className="link" onClick={closeDropdown}>
                Our Edge
              </a>
              <a href="#testimonials" className="link" onClick={closeDropdown}>
                Testimonials
              </a>
            </div>
            <div className="third-div flex">
              <Link
                to="/waiting-list"
                className="link join-waiting-list text-decoration-none rounded-pill"
              >
                Join The waiting list
              </Link>
              <Link to="/signup" className="link text-decoration-none rounded-pill">
                Sign Up
              </Link>
              <img src={signupTopbar} alt="signup-hint" className="signup-hint"/>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Topbar;
