import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./Pages/signup.jsx";
import NotFound from "./Pages/notFound.jsx";
import LandingPage from "./Pages/landingPage.jsx";
import WaitingList from "./Pages/waitingList.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "../src/assets/css/signup.css";
import LandingPage2 from "./Pages/landingPage2.jsx";
import Dashboard from "./Pages/dashboard/index.jsx";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/2" element={<LandingPage2 />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/waiting-list" element={<WaitingList />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
