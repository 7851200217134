import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RxCross1 } from "react-icons/rx";
import Topbar from "../Components/topbar";
import Services from "../Components/services";
import { IoCheckmarkSharp } from "react-icons/io5";
import "../assets/css/landingPage.css";

import bgImg from "../assets/images/landing-page-bg.png";
import logo from "../assets/images/landing-page-logo.png";
import laptop from "../assets/images/landing-page-laptop.png";
import polygon from "../assets/images/landing-page-polygon.png";
import HowWork from "../Components/howWork";
import Pricing from "../Components/pricing";
import Footer from "../Components/footer";
import Testimonials from "../Components/testimonials";
import Edge from "../Components/edge";
import { Alert, Modal } from "react-bootstrap";

const LandingPage2 = () => {
  const [showAlertSuccess, setShowAlertSuccess] = useState(true);
  const [showAlertError, setShowAlertError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Email is verified");
  const [emailErrors, setEmailErrors] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlertSuccess(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log(values);
    setSubmitting(true);
    const data = new FormData();

    try {
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .test(
          "is-trimmed",
          "Email must not have leading or trailing spaces",
          (value) => value === value?.trim()
        )
        .email("Invalid email format")
        .required("Email is required"),
    });
  const [showResponse, setShowResponse] = useState(true);
  const handleCloseResponse = async () => {
    setShowResponse(false);
    setEmailErrors();
  };
  return (
    <div className="page landing-page">
      {/* ==================== Hero Section =============== */}
      <div className="landing-page-main">
        <Topbar />
        <div className="bd-example">
          <div className="d-flex justify-content-between flex-wrap">
            <Modal
              show={showResponse}
              onHide={handleCloseResponse}
              backdrop="true"
              keyboard={true}
              centered
            >
              <div className="modal-dialog modal-confirm m-0">
                <div className="modal-content align-items-center">
                  <div className="modal-header">
                    <div
                      className={`icon-box ${
                        showAlertSuccess ? "bg-success" : "bg-danger"
                      }`}
                    >
                      <i className="material-icons d-flex align-items-center justify-content-center">
                        {showAlertSuccess ? <IoCheckmarkSharp /> : <RxCross1 />}
                      </i>
                    </div>
                    <h4 className="modal-title align-items-center">
                      {showAlertSuccess ? "Awesome" : "Sorry"}!
                    </h4>
                  </div>
                  <div className="modal-body">
                    <p className="text-center">{alertMessage}</p>
                  </div>

                  <button
                    type="button"
                    onClick={handleCloseResponse}
                    className={`btn ${
                      showAlertSuccess ? "btn-success" : "btn-danger"
                    }`}
                    style={{ width: "-webkit-fill-available !important;" }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="main-div">
          <img
            src={bgImg}
            alt="bg-img"
            className="position-absolute top-0 bg-img"
          />
          <div className="hero d-flex">
            <div className="first-half-div">
              <img src={logo} alt="logo" />
              <h1>
                Simplifying Medical <br /> Research & Collaboration
              </h1>
              <p>
                <b>
                  Join Thousands in the Healthcare Community & Elevate your
                  Research
                </b>{" "}
                <br /> Welcome to G LNK Connect+. We empower medical
                professionals and researchers to connect, collaborate on
                groundbreaking research, and discover new opportunities. Whether
                you need a peer reviewer or are planning your next milestone, G
                LNK provides AI-driven insights within a community committed to
                advancing healthcare.
              </p>

              <Formik
                enableReinitialize={true}
                initialValues={{
                  ...formData,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnChange={true} // Validate on every change
                validateOnBlur={true} // Validate on blur
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  errors,
                  setFieldTouched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="btn-div">
                      <input
                        type="email"
                        onChange={(e) => {
                          handleChange(e);
                          // Mark the field as touched when user starts typing
                          setFieldTouched("email", true, false);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        className={`email-input ${
                          errors.email && touched.email ? "input-error" : ""
                        }`}
                        name="email"
                        id="email"
                        placeholder="Enter Email"
                      />
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="text-uppercase"
                      >
                        Join the waiting list for free
                      </button>
                    </div>
                    {errors.email && touched.email && (
                      <span className="error-message">{errors.email}</span>
                    )}

                    {errors.submit && (
                      <span className="error-message">{errors.submit}</span>
                    )}
                    {emailErrors?.email?.map((error, index) => (
                      <span
                        key={index}
                        className="w-100 mt-1 small text-danger"
                      >
                        {error}
                        <br />
                      </span>
                    ))}
                  </form>
                )}
              </Formik>
            </div>
            <div className="second-half-div">
              <img src={laptop} alt="laptop" className="laptop" />
            </div>
          </div>
          <img src={polygon} alt="polygon" className="polygon" />
        </div>
      </div>
      <Services />
      <HowWork />
      <Pricing />
      <Edge />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default LandingPage2;
