import React from "react";
import "../assets/css/services.css";

import serviceGroup from "../assets/images/service-group.png";
import manageGroup from "../assets/images/manage-group.png";
import carearSupport from "../assets/images/carear-support.png";
import arrow from "../assets/images/functionalities-arrow.png";

function Services() {
  return (
    <div className="services" id="services">
      <h1 className="text-center fw-bold main-heading">Our Services</h1>
      <div className="flex grow-community">
        <div className="w-50 text-div">
          <h3 className="fw-bold">Grow Your Community</h3>
          <p className="fst-italic bold-para">
            Expand Your Professional Connections Effortlessly
          </p>
          <p className="simple-para">
            G LNK Connect+ simplifies professional networking, helping you
            connect with peers and foster collaborations that advance your
            research.
          </p>
        </div>
        <div className="w-50 flex img-div">
          <img src={serviceGroup} alt="service-group" />
        </div>
      </div>
      <div className="key-functions">
        <h1 className="text-center fw-bold main-heading">
          Key Functionalities
        </h1>
        <div className="row grow-community-keys">
          <div className="row-line">
            <img src={arrow} alt="arrow" className="arrow-image" />
          </div>
          <div className="col-sm-4 row grow-community-keys-responsive">
            <div className="card">
              <div className="card-header text-white text-center">
                <h5 className="card-title">Optimized Reviewer Matching</h5>
              </div>
              <div className="card-body">
                <div className="card-text d-flex">
                  <p className="circle text-white flex">01</p>Proprietary AI
                  matches compatible peer reviewers and collaborators.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">02</p> Filters conflicts
                  of interest.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">03</p> Leverages
                  extensive data to ensure relevant and unbiased selections.
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 row">
            <div className="card">
              <div className="card-header text-white text-center">
                <h5 className="card-title">
                  Global & Cross-domain Connectivity
                </h5>
              </div>
              <div className="card-body">
                <div className="card-text d-flex">
                  <p className="circle text-white flex">01</p> Connect with
                  healthcare professionals globally.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">02</p> Dynamic
                  compatibility and intelligent search filters.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">03</p> Automated
                  calendar integrations for easy meeting setups.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">04</p> Missed
                  opportunity analysis and location-based suggestions for
                  optimal collaboration.
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 row">
            <div className="card">
              <div className="card-header text-white text-center">
                <h5 className="card-title">
                  Tailored <br /> Opportunities
                </h5>
              </div>

              <div className="card-body">
                <div className="card-text d-flex">
                  <p className="circle text-white flex">01</p> Receive
                  customized recommendations for conferences, webinars, and new
                  connections.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">02</p> Aligns with your
                  professional goals and availability.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex grow-community manage-research">
        <div className="w-50 text-div">
          <h3 className="fw-bold">Manage Your Research</h3>
          <p className="fst-italic bold-para">
            Organize and Streamline Your Research Projects
          </p>
          <p className="simple-para">
            With G LNK, managing your research has never been simpler. Our tools
            ensure that your projects stay on track, so you can focus on what
            really matters—advancing your research.
          </p>
        </div>
        <div className="w-50 flex img-div">
          <img src={manageGroup} alt="manage-group" />
        </div>
      </div>
      <div className="key-functions">
        <h1 className="text-center fw-bold main-heading">
          Key Functionalities
        </h1>
        <div className="row grow-community-keys">
          <div className="row-line">
            <img src={arrow} alt="arrow" className="arrow-image" />
          </div>
          <div className="col-sm-4 row">
            <div className="card">
              <div className="card-header text-white text-center">
                <h5 className="card-title">Integrated Project Management</h5>
              </div>
              <div className="card-body">
                <div className="card-text d-flex">
                  <p className="circle text-white flex">01</p>Centralized
                  platform syncing communication, drive management, and
                  scheduling.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">02</p>Boosts
                  collaboration and productivity across your team.
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 row">
            <div className="card">
              <div className="card-header text-white text-center">
                <h5 className="card-title">AI-Driven Research Planning</h5>
              </div>
              <div className="card-body">
                <div className="card-text d-flex">
                  <p className="circle text-white flex">01</p> Advanced AI
                  generates comprehensive research proposals.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">02</p>Identifies optimal
                  labs, collaborators, and funding sources.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">03</p> Provides detailed
                  cost analysis and timelines for streamlined project execution.
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 row">
            <div className="card">
              <div className="card-header text-white text-center">
                <h5 className="card-title">
                  Dynamic Network Visualization & Filtering
                </h5>
              </div>

              <div className="card-body">
                <div className="card-text d-flex">
                  <p className="circle text-white flex">01</p> Interactive
                  network web for visualizing and filtering relevant research
                  stakeholders and KOLs.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">02</p> Enables targeted
                  collaboration and efficient network navigation.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex grow-community manage-research carear-research">
        <div className="w-50 text-div">
          <h3 className="fw-bold">Research & Career Support</h3>
          <p className="fst-italic bold-para">
            Enhance Your Research and Advance Your Career
          </p>
          <p className="simple-para">
            G LNK Connect+ enhances your research and career by providing
            tailored opportunities and connecting you with key stakeholders.
          </p>
        </div>
        <div className="w-50 flex img-div">
          <img src={carearSupport} alt="carear-support" />
        </div>
      </div>
      <div className="key-functions">
        <h1 className="text-center fw-bold main-heading">
          Key Functionalities
        </h1>
        <div className="row grow-community-keys">
          <div className="row-line">
            <img src={arrow} alt="arrow" className="arrow-image" />
          </div>
          <div className="col-sm-4 row">
            <div className="card">
              <div className="card-header text-white text-center">
                <h5 className="card-title">
                  Research-Pharma Collaboration Gateway
                </h5>
              </div>
              <div className="card-body">
                <div className="card-text d-flex">
                  <p className="circle text-white flex">01</p>Facilitates
                  strategic partnerships between researchers and pharmaceutical
                  companies.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">02</p>Accelerates drug
                  development and innovation.
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 row">
            <div className="card">
              <div className="card-header text-white text-center">
                <h5 className="card-title">
                  Healthcare Organization Integration
                </h5>
              </div>
              <div className="card-body">
                <div className="card-text d-flex">
                  <p className="circle text-white flex">01</p> Connects
                  researchers with healthcare organizations.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">02</p>Swiftly translates
                  cutting-edge research into clinical practice and healthcare
                  systems.
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 row">
            <div className="card">
              <div className="card-header text-white text-center">
                <h5 className="card-title">
                  Cross-Industry Networking & Career Advancement
                </h5>
              </div>

              <div className="card-body">
                <div className="card-text d-flex align-items-start">
                  <p className="circle text-white flex">01</p> Enables
                  researchers to explore and pursue cross-industry
                  opportunities.
                </div>
                <div className="card-text d-flex">
                  <p className="circle text-white flex">02</p> Aligns expertise
                  with roles that enhance career growth across research, pharma,
                  and healthcare sectors.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
