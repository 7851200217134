import React, { useEffect, useState } from "react";
import "../assets/css/testimonials.css";
import doctorFemaleAvatar from "../assets/images/female-doctor.png";
import researcherAvatar from "../assets/images/researcher.jpg";
import doctorFemale1Avatar from "../assets/images/woman-doctor.png";
import colonsAvatar from "../assets/images/colon-avatar.png";

const Testimonials = () => {
  const testimonials = [
    {
      id: 0,
      text: `"Managing research projects has never been easier. The centralized platform keeps everything organized, from scheduling meetings to sharing documents. I love how intuitive the interface is—G LNK Connect+ has become an indispensable tool for my work."`,
      author: "Dr. Michael T.",
      role: "Oncology Researcher",
      image: researcherAvatar,
    },
    {
      id: 1,
      text: `"G LNK Connect+ has transformed how I collaborate with peers globally. The AI-driven networking is a game-changer, making it easier than ever to build meaningful connections that enhance my research."`,
      author: "Dr. Emily R.",
      role: "Senior Cardiologist",
      image: doctorFemaleAvatar,
    },
    {
      id: 2,
      text: `"I've been using G LNK Connect+ for a few months now, and it's made networking so much simpler. The ability to connect with like-minded professionals worldwide has enriched my research and opened up opportunities I wouldn't have found otherwise."`,
      author: "Prof. Sarah B.",
      role: "Professor of Epidemiology",
      image: doctorFemale1Avatar,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateClass, setAnimateClass] = useState("");
  const [readMoreIndex, setReadMoreIndex] = useState(null);

  const toggleReadMore = (index) => {
    setReadMoreIndex(readMoreIndex === index ? null : index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimateClass("");
      setTimeout(() => {
        setAnimateClass("animate-in");
        setReadMoreIndex(null);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      }, 100);
    }, 5000);
    return () => clearInterval(interval);
  }, [testimonials.length]);

  const getVisibleTestimonials = () => {
    const first = testimonials[currentIndex];
    const second = testimonials[(currentIndex + 1) % testimonials.length];
    const third =
      currentIndex - 1 === -1
        ? testimonials[testimonials.length - 1]
        : testimonials[(currentIndex - 1) % testimonials.length];
    return [third, first, second];
  };

  const handleDotClick = (index) => {
    const isForward = index > currentIndex;
    setAnimateClass(isForward ? "animate-in" : "animate-out");
    setCurrentIndex(index);
    setReadMoreIndex(null);
    setTimeout(() => {
      setAnimateClass("");
    }, 500);
  };

  const getClassName = (index) => {
    const testimonialsCount = testimonials.length;
    if (index === currentIndex) {
      return `testimonial-item-large ${animateClass}`;
    } else if (
      index === (currentIndex + 1) % testimonialsCount ||
      (currentIndex === testimonialsCount - 1 && index === 0)
    ) {
      return `testimonial-item-visible ${animateClass}`;
    } else if (
      index === (currentIndex - 1 + testimonialsCount) % testimonialsCount ||
      (currentIndex === 0 && index === testimonialsCount - 1)
    ) {
      return `testimonial-item-visible ${animateClass}`;
    } else {
      return "testimonial-item-hidden";
    }
  };

  const renderText = (text, index) => {
    const maxTextLength = 170;
    return (
      <p className="testimonial-text fw-light fst-italic">
        {readMoreIndex === index || text.length <= maxTextLength
          ? text
          : `${text.slice(0, maxTextLength)}...`}
        {text.length > maxTextLength && (
          <span
            onClick={() => toggleReadMore(index)}
            style={{ color: "#82a9ea", cursor: "pointer" }}
          >
            {readMoreIndex !== index && " Read More"}
          </span>
        )}
      </p>
    );
  };

  return (
    <div className="testimonial-section py-sm-3 py-0" id="testimonials">
      <div className="text-center mb-sm-4 testimonials-heading">
        <h2 className="testimonial-heading mb-3 mt-sm-3 mt-0 text-center">
          Testimonials
        </h2>
      </div>
      <h2 className="text-center mb-sm-4 mt-sm-4 testimonial-desc">
        What our community thinks of G LNK Connect+
      </h2>
      <div className="slider mb-4">
        <div className="slider-main-div">
          <div className="carousel">
            {getVisibleTestimonials()?.map((testimonial, index) => (
              <div
                key={index}
                className={`testimonial-item ${getClassName(testimonial?.id)}`}
              >
                <div className="colon-img">
                  <img src={colonsAvatar} alt="colon-img" />
                </div>
                <div>
                  <p className="testimonial-text fw-light fst-italic">
                    {" "}
                    <div>{renderText(testimonial.text, testimonial.id)}</div>
                  </p>
                </div>
                <div className="d-flex">
                  <div className="testimonial-image">
                    <img src={testimonial.image} alt={testimonial.author} />
                  </div>
                  <div className="testimonial-author">
                    <h5 className="mt-3 testimonial-author-text">
                      {testimonial.author}
                    </h5>
                    <p className="testimonial-author-role">
                      {testimonial.role}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="dots-container">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
