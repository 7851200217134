import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Topbar from "../Components/topbar";
import { Alert } from "react-bootstrap";
import "../assets/css/waiting.css";

import bgImg from "../assets/images/landing-page-bg.png";
import logo from "../assets/images/landing-page-logo.png";
import laptop from "../assets/images/landing-page-laptop.png";
import mobile from "../assets/images/waiting-mobile.png";
import polygon from "../assets/images/landing-page-polygon.png";
const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

const WaitingList = () => {
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [emailErrors, setEmailErrors] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const form = new FormData();
    form.append("email", values.email);

    try {
      const response = await fetch(`${serverBaseUrl}/waitlist`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: form,
      });
      const responseData = await response.json();
      if (response.status === 201) {
        setShowAlertSuccess(true);
        setAlertMessage(responseData?.message);
        setTimeout(() => {
          setShowAlertSuccess(false);
        }, 5000);
      } else if (response.status === 422) {
        setShowAlertError(responseData?.message);
        setAlertMessage(responseData?.message);
        setTimeout(() => setShowAlertError(false), 3000);
        return;
      } else {
        setAlertMessage(responseData?.message);
        setShowAlertError(responseData?.message);
        setTimeout(() => setShowAlertError(false), 3000);
        return;
      }
    } catch (err) {
      console.error(err);
      setAlertMessage(err?.message);
      setShowAlertError(true);
      setTimeout(() => setShowAlertError(false), 3000);
    } finally {
      resetForm(true);
      setSubmitting(false);
    }
  };

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .test(
          "is-trimmed",
          "Email must not have leading or trailing spaces",
          (value) => value === value?.trim()
        )
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email format"
        )
        .email("Invalid email format")
        .required("Email is required"),
    });
  return (
    <div className="page-waitlist waitlist-page">
      <div className="waitlist-page-main">
        <Topbar menuBoolean={false} />
        <img
          src={bgImg}
          alt="bg-img"
          className="position-absolute top-0 bg-img"
        />
        <div className="hero d-flex">
          <div className="first-half-div">
            <img src={logo} alt="logo" />
            <h1>
              Simplifying Medical <br /> Research & Collaboration
            </h1>
            <p>
              <b>
                Join Thousands in the Healthcare Community & Elevate your
                Research
              </b>{" "}
              <br /> Welcome to G LNK Connect+. We empower medical professionals
              and researchers to connect, collaborate, and discover new
              opportunities. G LNK provides a centralized repository for funding
              opportunities, peers, and conferences/meetings, deploying
              AI-driven insights within a community committed to advancing
              healthcare.
            </p>
            <div className="btn-div-message">
              {showAlertSuccess && (
                <div className="bd-example">
                  <Alert
                    variant="success d-flex align-items-center"
                    role="alert"
                  >
                    <svg
                      className="me-2"
                      id="check-circle-fill"
                      width="20"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                    </svg>
                    <div className="me-4">{alertMessage}</div>
                    {"   "}
                    <button
                      type="button"
                      className="btn-close ms-auto"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setShowAlertSuccess(false)}
                    ></button>
                  </Alert>
                </div>
              )}

              {showAlertError && (
                <div className="bd-example">
                  <Alert
                    variant="danger d-flex align-items-center"
                    role="alert"
                  >
                    <svg
                      className="me-2"
                      id="exclamation-triangle-fill"
                      fill="currentColor"
                      width="20"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                    </svg>
                    <div className=" me-4">{alertMessage}</div>
                    {"   "}
                    <button
                      type="button"
                      className="btn-close ms-auto"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setShowAlertError(false)}
                    ></button>
                  </Alert>
                </div>
              )}
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                ...formData,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange={false} // Validate on every change
              validateOnBlur={false} // Validate on blur
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                touched,
                errors,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="btn-div-waitlist">
                    <input
                      type="email"
                      onChange={(e) => {
                        handleChange(e);
                        // Mark the field as touched when user starts typing
                        setFieldTouched("email", true, false);
                      }}
                      onBlur={handleBlur}
                      value={values.email}
                      className={`email-input ${
                        errors.email && touched.email ? "input-error" : ""
                      }`}
                      name="email"
                      id="email"
                      placeholder="Enter Email"
                    />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="text-uppercase"
                    >
                      Join the waiting list for free
                    </button>
                  </div>
                  {errors.email && touched.email && (
                    <span className="error-message">{errors.email}</span>
                  )}

                  {errors.submit && (
                    <span className="error-message">{errors.submit}</span>
                  )}
                  {emailErrors?.email?.map((error, index) => (
                    <span key={index} className="w-100 mt-1 small text-danger">
                      {error}
                      <br />
                    </span>
                  ))}
                </form>
              )}
            </Formik>
          </div>
          <div className="second-half-div">
            <img src={laptop} alt="laptop" className="laptop device" />
            <img src={mobile} alt="mobile" className="mobile device" />
          </div>
        </div>
        <img src={polygon} alt="polygon" className="polygon" />
      </div>
    </div>
  );
};

export default WaitingList;
