import React from "react";
import "../../assets/css/dashboard.css";
import dashboard from "../../assets/images/dashboard.svg";

const Dashboard = () => {
  return (
    <div className="dashboard">
      <img src={dashboard} alt="" srcset="" />
    </div>
  );
};

export default Dashboard;
