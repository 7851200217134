import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "../assets/css/howWork.css";

import slideOne from "../assets/images/slide-one.png";
import slideTwo from "../assets/images/slide-two.png";
import slideThree from "../assets/images/slide-three.png";
import slideFour from "../assets/images/slide-four.png";
import slideFive from "../assets/images/slide-five.png";
import slideSix from "../assets/images/slide-six.png";
import { Link } from "react-router-dom";

function HowWork() {
  const slides = [
    { component: <SlideOne />, image: slideOne },
    { component: <SlideTwo />, image: slideTwo },
    { component: <SlideThree />, image: slideThree },
    { component: <SlideFour />, image: slideFour },
    { component: <SlideFive />, image: slideFive },
    { component: <SlideSix />, image: slideSix },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const intervalRef = useRef(null);
  const hasStarted = useRef(false);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide < slides.length - 1 ? prevSlide + 1 : 0
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide > 0 ? prevSlide - 1 : slides.length - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };
  useEffect(() => {
    slides.forEach((slide) => {
      const img = new Image();
      img.src = slide.image;
    });

    const startSlider = () => {
      if (!hasStarted.current) {
        intervalRef.current = setInterval(() => {
          nextSlide();
        }, 5000);
        hasStarted.current = true;
      }
    };

    const stopSlider = () => {
      clearInterval(intervalRef.current);
      hasStarted.current = false;
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          startSlider();
        } else {
          stopSlider();
        }
      },
      { threshold: 0.5 }
    );

    if (sliderRef.current) {
      observer.observe(sliderRef.current);
    }

    return () => {
      stopSlider();
      if (sliderRef.current) {
        observer.unobserve(sliderRef.current);
      }
    };
  }, [slides]);

  return (
    <div className="how-work-section">
      <div className="how-work-div" id="how-work">
        <h1 className="text-center">How it works?</h1>
        <p className="text-center">
          Discover how G LNK simplifies and streamlines the process of
          determining fair market valuations for healthcare professionals.
        </p>
      </div>
      <div className="slider" ref={sliderRef}>
        <div className="slider-main-div">
          <div className="btn-div right-side-btn flex">
            <button
              onClick={prevSlide}
              disabled={currentSlide === 0}
              className={`flex ${currentSlide === 0 && "disabled"}`}
            >
              <span>
                <IoIosArrowBack className="how-to-work-icon" color="#fff" />
              </span>
            </button>
          </div>
          {slides[currentSlide].component}
          <div className="btn-div left-side-btn flex">
            <button
              onClick={nextSlide}
              disabled={currentSlide === slides.length - 1}
              className={`flex ${
                currentSlide === slides.length - 1 && "disabled"
              }`}
            >
              <span>
                <IoIosArrowForward className="how-to-work-icon" color="#fff" />
              </span>
            </button>
          </div>
        </div>
        <div className="dots-container flex">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
      </div>
      <div className="join-glink">
        <h1>Join G LNK Connect+ Today</h1>
        <p>Take control of your professional journey.</p>
        <p>Sign up now and start connecting,</p>
        <p>collaborating, and advancing in</p>
        <p>the world of healthcare.</p>
        <Link to="/signup">
          <button className="text-white">
            <span>Sign Up Now and Get 3 Month Free </span>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default HowWork;

function LazyImage({ src, alt }) {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div className="image-container">
      {!isLoaded && <div className="image-placeholder" />}
      <img
        src={src}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        style={{ opacity: isLoaded ? 1 : 0, transition: "opacity 1s" }}
        className="slider-image"
      />
    </div>
  );
}

function SlideOne() {
  return (
    <div className="slide flex">
      <div className="content-div flex">
        <div className="align-items-start flex">
          <div className="circle flex text-white">01</div>
          <div className="main-content">
            <div className="circle-responsive">
              <div className="circle-responsive-inner flex text-white">01</div>
              <h3>How G LNK Connect+ Works</h3>
            </div>

            <h3>How G LNK Connect+ Works</h3>
            <p className="bold-para">
              Streamline Your Networking, Research, and Career Development with
              Ease
            </p>
            <p className="simple-para">
              Discover how G LNK Connect+ empowers healthcare professionals to
              connect,collaborate, and advance their careers through an
              intuitive and powerful platform.
            </p>
          </div>
        </div>
      </div>
      <div className="img-div flex">
        <LazyImage src={slideOne} alt="slide-one" />
      </div>
    </div>
  );
}

function SlideTwo() {
  return (
    <div className="slide reverse-slide new-slide flex">
      <div className="img-div flex">
        <LazyImage src={slideTwo} alt="slide-two" />
      </div>
      <div className="content-div flex">
        <div className="align-items-start flex">
          <div className="circle flex text-white">02</div>
          <div className="main-content">
            <div className="circle-responsive">
              <div className="circle-responsive-inner flex text-white">02</div>
              <h3>Step 1: Effortless Onboarding</h3>
            </div>
            <h3>Step 1: Effortless Onboarding</h3>
            <p className="bold-para">Get Started in Minutes</p>
            <p className="simple-para">
              Quickly set up your profile and customize your research interests.
              Sync with your existing networks for a seamless experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function SlideThree() {
  return (
    <div className="slide new-slide flex">
      <div className="content-div flex">
        <div className="align-items-start flex">
          <div className="circle flex text-white">03</div>
          <div className="main-content">
            <div className="circle-responsive">
              <div className="circle-responsive-inner flex text-white">03</div>
              <h3>Step 2: Smart Networking</h3>
            </div>
            <h3>Step 2: Smart Networking</h3>
            <p className="bold-para">Build Impactful, Lasting Connections</p>
            <p className="simple-para">
              AI-driven suggestions connect you with peers, mentors, and
              collaborators who share your research goals and interests.
            </p>
          </div>
        </div>
      </div>
      <div className="img-div flex">
        <LazyImage src={slideThree} alt="slide-three" />
      </div>
    </div>
  );
}

function SlideFour() {
  return (
    <div className="slide reverse-slide new-slide flex">
      <div className="img-div flex">
        <LazyImage src={slideFour} alt="slide-four" />
      </div>
      <div className="content-div flex">
        <div className="align-items-start flex">
          <div className="circle flex text-white">04</div>
          <div className="main-content">
            <div className="circle-responsive">
              <div className="circle-responsive-inner flex text-white">04</div>
              <h3>Step 3: Streamlined Research Management</h3>
            </div>
            <h3>Step 3: Streamlined Research Management</h3>
            <p className="bold-para">Organize Your Research</p>
            <p className="simple-para">
              Manage your projects, track deadlines, and organize all your
              research materials in one place with our intuitive and
              customizable dashboard.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function SlideFive() {
  return (
    <div className="slide five-slide flex">
      <div className="content-div flex">
        <div className="align-items-start flex">
          <div className="circle flex text-white">05</div>
          <div className="main-content">
            <div className="circle-responsive">
              <div className="circle-responsive-inner flex text-white">05</div>
              <h3>Step 4: Seamless Collaboration</h3>
            </div>
            <h3>Step 4: Seamless Collaboration</h3>
            <p className="bold-para">Collaborate Instantly</p>
            <p className="simple-para">
              Connect with your network in real-time. Share documents, initiate
              conversations, and work together to move your research forward.
            </p>
          </div>
        </div>
      </div>
      <div className="img-div flex">
        <LazyImage src={slideFive} alt="slide-five" />
      </div>
    </div>
  );
}

function SlideSix() {
  return (
    <div className="slide reverse-slide five-slide flex">
      <div className="img-div flex">
        <LazyImage src={slideSix} alt="slide-six" />
      </div>
      <div className="content-div flex">
        <div className="align-items-start flex">
          <div className="circle flex text-white">06</div>
          <div className="main-content">
            <div className="circle-responsive">
              <div className="circle-responsive-inner flex text-white">06</div>
              <h3>Step 5: Discover New Opportunities</h3>
            </div>
            <h3>Step 5: Discover New Opportunities</h3>
            <p className="bold-para">Unlock Your Potential</p>
            <p className="simple-para">
              Receive tailored recommendations for career advancement. Whether
              it's grants, conferences, or peer review opportunities, G LNK
              Connect+ has you covered.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
